@import "../../../styles/mixin";
@import "../../../styles/variables"; 

.register_container {
  width: 100%;
  min-height: 100vh;
  display: grid;
  place-items: center;
  gap: 1rem;
  padding: 1rem;
}

.container-buttons-register {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

}

//Steps 2
.card-matter-item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: .5rem;
  cursor: pointer;
  p:hover {
    color: lighten($blue-second, 9)
  }
}.matters-grid {
  width: 100%;
  display: grid;
  gap: 1rem;

  @include from(m) {
    grid-template-columns: repeat(2, 1fr);
  }
  @include from(l) {
    grid-template-columns: repeat(4, 1fr);
  }
}

// Steps 3
.grid-input {
  display: grid;
  gap: 1rem;
  &.cols-2 {
    grid-template-columns: repeat(2, 1fr);
  }
}

.terms_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.ant-steps-item-icon .ant-steps-icon {
  position: relative;
  top: -2.5px !important;
  color: #1890ff;
  line-height: 1;
}


.price-table-item {
  width: auto;
  max-width: 300px;
  height: auto;
  background-color: white;
  box-shadow: var(--shadow);
  text-align: center;
  border-radius: 30px;
  padding-bottom: 1rem;
}
.table-item {
 
  width: auto;
  max-width: 300px;
  height: auto;
  background-color: white;
  box-shadow: var(--shadow);
  text-align: center;
  border-radius: 30px;
  padding-bottom: 1rem; 
    
    
  } 
  .table-item-selection {
    border: 0.5px solid #4C8170;
    width: auto;
    max-width: 300px;
    height: auto;
    background-color: white;
    box-shadow: var(--shadow);
    text-align: center;
    border-radius: 30px;
    padding-bottom: 1rem;   
  }

.price-table-header {
  position: relative;
}

.table-header-image {
  width: 100%;
}

.table-header-content {
  background-color: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.price-table-list {
  background-color: transparent;
  padding: 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
  ul {
    padding-left: 0;
    li {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: .5rem;
      padding: .3rem 0;
      border-bottom: 1px solid var(--green-second);
    }
  }
}


  .boton-select{
    background-color: #0C4393 !important;
    color: white !important;
  }

  .profile-form-row {
    width: 100%;
  }
  
  .profile-form-row-grid {
    @include from(720px) {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 1rem;
    }
  }
  
  .profile-form-buttons {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
    @include from(m) {
      justify-content: flex-end;
    }
  }
  
  .button_subscription{
    background-color: #ffff !important;
    color: #02023b !important;
  }

  @media screen and (min-width: 840px) {
    .row {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      height: 26%;
    } 
    
    
   
    .col-card {
      flex: 1;
    }
   
    .col-05 {
      flex: 0.5;
    }
  
    .col-10 {
      flex: 1;
    }
  
    .col-15 {
      flex: 1.5;
    }
    
    .col-20 {
      flex: 2;
    }
    
    .col-25 {
      flex: 2.5;
    }
    
    .col-30 {
      flex: 3;
    }
    
    .col-35 {
      flex: 3.5;
    }
  
    .col-40 {
      flex: 4;
    }
    
    .col-45 {
      flex: 4.5;
    }
    
    .col-50 {
      flex: 5;
    }
    
    .col-55 {
      flex: 5.5;
    }
    
    .col-60 {
      flex: 6;
    }
    
    .col-65 {
      flex: 6.5;
    }
    
    .col-70 {
      flex: 7;
    }
    
    .col-75 {
      flex: 7.5;
    }
    
    .col-80 {
      flex: 8;
    }
    
    .col-85 {
      flex: 8.5;
    }
    
    .col-90 {
      flex: 9;
    }
    
    .col-95 {
      flex: 9.5;
    }
  }
  


